import { ShareComponent } from './components/share/share.component';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { OrdersComponent } from './pages/orders/orders.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { SearchComponent } from './pages/search/search.component';
import { SearchchatComponent } from './pages/searchchat/searchchat.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { GlobalService } from './services/global.service';
import { HTTP } from '@ionic-native/http/ngx';
import { AuthModule } from './auth/auth.module';
import { HttpProvider } from './providers/http/http';
import { HttpAngularProvider } from './providers/http-angular/http-angular';
import { HttpNativeProvider } from './providers/http-native/http-native';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Camera } from '@ionic-native/camera/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { TitleCaseDirective } from './title-case.directive';
import { FilterComponent } from './pages/filter/filter.component';
import { VerificationComponent } from './pages/auth/verification/verification.component';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { SharedModule } from './shared/shared.module';


@NgModule({
  declarations: [AppComponent,
    ProductDetailsComponent,
    OrdersComponent,
    SearchComponent,
    SearchchatComponent,    
    CartComponent,
    ShareComponent,
    FilterComponent,
    CheckoutComponent,
    TitleCaseDirective,
    VerificationComponent],
    exports: [
      FormsModule,
      ReactiveFormsModule
    ],
  entryComponents: [ProductDetailsComponent,
    SearchComponent,
    OrdersComponent,
    ShareComponent,
    FilterComponent,
    SearchchatComponent,
    CartComponent,
    CheckoutComponent,
    VerificationComponent],
  imports: [BrowserModule,SharedModule,
    Ng2SearchPipeModule, ReactiveFormsModule,NgxIonicImageViewerModule,
    FormsModule, IonicModule.forRoot(), AppRoutingModule, AuthModule, HttpClientModule,
    BrowserAnimationsModule, IonicStorageModule.forRoot()],
  providers: [
    StatusBar,
    SplashScreen,
    NativeStorage,
    HTTP,
    FCM,
    Camera,
    BarcodeScanner,
    FileOpener,
    FileChooser,
    FilePath,
    File,
    PreviewAnyFile,
    DocumentViewer,
    WebView,
    HttpProvider,
    HttpAngularProvider,
    HttpNativeProvider,
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    GlobalService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
