import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiniaturaArticuloComponent } from '../components/miniatura-articulo/miniatura-articulo.component'
import { IonicModule } from '@ionic/angular';

import { Ng2SearchPipeModule } from 'ng2-search-filter';

@NgModule({
    declarations: 
        [MiniaturaArticuloComponent],
    imports: 
        [CommonModule, IonicModule,Ng2SearchPipeModule,
            CommonModule],
    exports:
        [MiniaturaArticuloComponent]
})
export class SharedModule {}
